<!--地标地图-->
<template>
  <div class="cityDiv">
    <!--面包屑-->
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <!--内容部分-->
    <div class="content-div">
      <!--标题-->
      <div class="city-title">
        <span>{{cityName}}</span>
      </div>
      <!--tab页切换-->
      <el-tabs class="firstSort">
        <el-tab-pane v-for="(item, index) in sortList" :key="index" :label="item.name">
          <div slot="label" @click="sortClick(item)">
            {{item.name}}
          </div>
        </el-tab-pane>
      </el-tabs>
      <!--条数-->
      <div class="totalDiv">
        共 <div class="totalNum">{{tableTotal}}</div>个结果
      </div>
      <!--列表部分-->
      <div class="listContain">
        <div class="infoDiv" v-for="(item, index) in productList" :key="index" @click="toDetail(item)">
          <img :src="item.img" v-if="item.img" alt="" />
          <div class="textDiv">
            <!--标题-->
            <div class="infoTitle">{{ item.giProductsName }}</div>
            <!--简介-->
            <div class="introduction">{{item.remak}}</div>
            <!--此方法翻页后内容不刷新-->
            <!--<text-ellipsis :text="item.remak" :height="100" style="width: 100%" class="introduction">
              <template slot="more">...</template>
            </text-ellipsis>-->
            <!--地址-->
            <div class="address"><img src="@/assets/img/addressIcon.png" alt="" style="margin-right: 10px">{{item.giProductsProducer}}</div>
          </div>

          <!--<div class="projectContent">
            <div class="product-item-title" :title="item.name">{{ item.productName }}</div>
            <div class="product-item-content">
              <text-ellipsis :text="item.productInfo" :height="75" style="width: 100%">
                <template slot="more">...</template>
              </text-ellipsis>
            </div>
          </div>-->
        </div>
      </div>
      <!--分页-->
      <div class="page">
        <el-pagination
          :page-sizes="[12, 20, 50, 100]"
          :page-size="pageSize"
          :current-page.sync="page"
          @current-change="pageChange"
          @size-change="handleSizeChange"
          @prev-click="prevClick"
          @next-click="nextClick"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal">
        </el-pagination>
      </div>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
import textEllipsis from '@/components/textellipsis'
export default {
  name: "giMap",
  components: { topPathList, textEllipsis },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标地图',
          path: '/map/giMap'
        },
      ],
      cityName: "", //城市名称
      giRegionId: "", //城市id
      sortList: [
        {
          type: '1',
          name:'地理标志证明/集体商标'
        },
        {
          type: '2',
          name:'地理标志保护产品'
        },
        {
          type: '3',
          name:'农产品地理标志'
        },
      ], //分类
      page: 1,
      tableTotal: 0,
      pageSize: 12,
      type: "1", //0地理标志保护产品，1地理标志证明/集体商标 2农产品
      productList: [],

    }
  },
  mounted() {
    //将路由带过来的cityName和giRegionId存储在session中，用于面包屑跳转
    this.giRegionId = this.$route.query.giRegionId
    this.cityName = this.$route.query.cityName
    sessionStorage.setItem('giRegionId', JSON.stringify(this.giRegionId));
    sessionStorage.setItem('cityName', JSON.stringify(this.cityName));
    this.getCity()
  },
  methods: {
    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getCity();
    },
    pageChange(val) {
      this.page = val
      this.getCity();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getCity()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getCity()
    },

    getCity() {
      this.giRegionId = this.$route.query.giRegionId
      this.cityName = this.$route.query.cityName.substring(0, 2)
      //调用接口查询列表信息
      let params = {
        current: this.page,
        size: this.pageSize,
        //id: this.giRegionId,
        county: this.cityName,
        giSign: this.type
      }
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/app/product/show",
        params: params,
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.productList = res.data.data.records;
          for (let i = 0; i < this.productList.length; i++) {
            this.productList[i].img = this.productList[i].giProductsPic.split(',')[0] //用，分隔取第1个
          }
          this.tableTotal = res.data.data.total;
        } else {
          this.$message.warning('请求失败')
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //点击切换
    sortClick(item) {
      this.page = 1 //tab点击切换返回第1页
      this.pageSize = 12 //每页条数初始化
      this.type = item.type
      this.getCity()
    },

    //跳转详情
    toDetail(item) {
      let pathObj = this.pathObj;
      pathObj.push({
        name: '详情',
        path: '/map/giMapDetail'
      })
      this.$router.push({
        path: `/map/giMapDetail`,
        query: {
          pathObj: pathObj,
          title: item.giProductsName,
          content: item.remak,
          pic: item.giProductsPic,
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
  .cityDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .content-div {
    margin: auto;
    padding: 32px 20px 12px 20px;
    width: 1160px;
    background: #FFFFFF;
    .city-title {
      text-align: center;
      font-size: 26px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
    }
    .totalDiv {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      line-height: 21px;
      .totalNum {
        color: #186CF5;
      }
    }
    /*.product-div {
      min-height: 387px;
      .product-item {
        width: 1087px;
        margin: 0px auto 29px;
        padding: 13px 16px;
        background: #FFFFFF;
        border-radius: 5px;
        height: 161px;
        .projectContent {
          text-align: left;
          margin-top: 17px;
          .product-item-title {
            font-size: 18px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 27px;
            overflow: hidden;//超出隐藏
            text-overflow: ellipsis;//显示省略号
            white-space: nowrap;//禁止文本自动换行
          }
          .product-item-content {
            margin-top: 12px;
            text-align: left;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #666666;
            line-height: 23px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
        }
      }
      .product-item:hover {
        cursor: pointer;
      }
    }*/

    .listContain {
      min-height: 335px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .infoDiv {
        width: 270px;
        margin: 32px 9px;
        background: #FFFFFF;
        border-radius: 5px;
        border: 1px solid #EEF2F9;
        cursor: pointer;
        text-align: center;
      }
      .infoDiv:hover {
        //background-color: #f0f7fd;
        border: 1px solid #dde5ef;
      }
      .infoDiv > img {
        width: 270px;
        height: 176px;
        border-radius: 5px;
      }
      .textDiv {
        margin: 10px;
        .infoTitle {
          text-align: left;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #333333;
          line-height: 27px;
          margin: 18px 0px;
        }
        .introduction {
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .address {
          display: flex;
          align-items: center;
          text-align: left;
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #7E91BE;
          line-height: 18px;
          margin: 10px 0px;
        }
      }
    }
  }
  .page {
    margin-bottom: 20px;
  }

</style>
